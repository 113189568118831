import React from 'react'
import { Link } from 'gatsby'
import { graphql } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'

import Layout from "../components/layout"
import Seo from "../components/seo"
import Header from '../components/sections/Header'
import SplitImage2 from '../components/sections/SplitImage2'
import Cta from '../components/sections/Cta'
import NewHeader from '../components/sections/NewHeader'
import Features3 from '../components/sections/Features3'
import Team from '../components/sections/Team'

import image from '../images/family.png'

const about = ({ data }) => {

    return (
        <Layout>
            <Seo title="About Us" />
            <SplitImage2
                imageleft="true"
                heading="Roseman Wealth Advisors"
                content="We are a team-based comprehensive financial advisory company located in Tyler, Texas, whose professionals work with clients across the country. Our mission is to help individuals and families realize that the purpose for their wealth is to facilitate their life purpose, and then guide them towards the fulfillment of that purpose in all areas of their financial life. We start with a financial plan and support that plan with sound investment management, insurance planning, and tax planning."
                button="Our Services"
                buttonpath="/services"
                link="Meet Our Team"
                linkpath="#team"
                pic="1001"
            />
            <Features3 />
            <div id="team">
                <Team />
            </div>
            <Cta />
        </Layout >
    )
}

export default about
