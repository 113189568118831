import React from 'react';
import { FingerPrintIcon, UserGroupIcon, AcademicCapIcon } from '@heroicons/react/24/outline'

const Features3 = () => {

    const data = [
        {
            title: "Independent",
            description: "We are not employees of a bank, fund company, or insurance company. This gives us the freedom to do what we believe is best for our clients.",
            icon: FingerPrintIcon,
        },
        {
            title: "Team Based",
            description: "Our team is multi-generational and multi-disciplined providing collaboration and continuity.",
            icon: UserGroupIcon,
        },
        {
            title: "Credentialed",
            description: "Our team has top industry credentials in areas including Financial Planning (CFP®), Investment Management (CFA), Tax Planning (CPA), and others.",
            icon: AcademicCapIcon
        }
    ]

    return (
        <div className="">
            <div className="container mx-auto px-5 py-12">

                < div className="grid lg:grid-cols-3 lg:divide-x" >
                    {
                        data.map((item) => (
                            <div className="flex flex-col py-8 lg:py-16 px-2 md:px-12">
                                <div class='w-16 h-16 inline-flex sm:mr-4 sm:mb-0 mb-2 lg:mb-4 items-center justify-center rounded-full flex-shrink-0 bg-blue-500'>
                                    <item.icon className='h-8 w-8 text-blue-100' />
                                </div>
                                <small className='mt-6 text-blue-500'>{item.title}</small>
                                {/* <h3 className={`mb-4 ${selected ? '' : 'text-gray-600 group-hover:text-gray-900'}`}>{item.subtitle}</h3> */}
                                <p class='grow text-left leading-relaxed text-sm text-gray-500'>{item.description}</p>
                            </div>
                        ))
                    }
                </ div>
            </div >

        </div >
    )
};

export default Features3;
